#home-page {
  #left-col {

  }
  #center-col {
    h1 {
      font-size: 22px;
      line-height: 25px;
      margin: 5px 0 0 0;
    }
    p {
      font-size: 14px;
      line-height: 19px;
      margin: 0;
    }
    .insta-quote {
      padding: 0;
      width: 49%;
      max-width: 495px;
      height: 310px;
      float: left;
      display: table;
      background: url('../../images/instant-quote-back.png') bottom left no-repeat $lightGrey;

      h2 {
        background: $lightMain;
        line-height: 55px;
        color: #fff;
        text-transform: uppercase;
        text-align: center;
        padding: 0;
        margin: 0;
        display: table-row;
      }

      .insta-quote-inside {
        padding: 5px 10px 0 140px;
        height: 100%;
        display: table-cell;
        vertical-align: middle;

        p {
          font-family: $monts;
          font-size: 16px;
          line-height: 19px;
          color: #000;
        }
        ul {
          padding: 0;
          margin: 0;
          list-style: none;
        }
        li {
          font-family: $monts;
          font-size: 13px;
          line-height: 15px;
          color: #000;
          list-style: none;

          span {
            font-family: $monts;
            font-weight: bold;
          }
        }
        h3 {
          font-family: $monts;
          font-size: 16px;
          line-height: 18px;
          margin: 10px 0 0 0;
          padding: 0;
          text-transform: uppercase;
        }
        input {
          padding: 5px 2px;
          width: 100%;
          font-size: 16px;
          line-height: 19px;
          display: block;
          &[type="text"] {
            font-family: $monts;
            color: #000;
          }
          &[type="submit"] {
            font-family: $monts;
            color: #fff;
            margin: 5px 0 0 0;
            background: $lightMain;
            text-transform: uppercase;
          }
        }
      }
    }
    .we-are {
      padding: 0;
      width: 49%;
      max-width: 495px;
      height: 310px;
      float: right;
      display: table;
      background: $lightGrey;

      .info {
        display: table-cell;
        vertical-align: middle;
        height: 100%;
        text-align: center;
      }
      img {
        display: block;
        width: 100%;
        max-width: 340px;
        text-align: center;
        margin: 0 auto;
      }
      h4 {
        font-family: $monts;
        font-size: 16px;
        margin: 10px 0;
        color: #000;
        font-weight: bold;
        text-align: center;
      }
      p {
        font-family: $monts;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
      }
      .we-are-list {
        font-family: $monts;
        font-size: 16px;
        line-height: 22px;
        margin: 10px auto;
        width: 80%;
        a {
          color: $headlineColor;
          font-weight: bold;
        }
      }
    }
    .reasons-10 {
      height: 100%;
      h2 {
        padding: 0;
        margin: 0;
        font-size: 28px;
        line-height: 28px;
        text-transform: uppercase;
        text-align: center;
        a {
          color: $headlineColor;
          &:hover {
            color: #000;
            text-decoration: none;
          }
        }
      }
      .list-10 {
        height: 100%;
        margin: 20px 0;
        ul {
          height: 100%;
          padding: 0;
          margin: 0;
          li {
            height: 50px;
            display: table;
            width: 100%;
            padding: 0 0 0 65px;
            background-repeat: no-repeat;
            font-size: 15px;
            line-height: 16px;
            margin-bottom: 8px;
            font-style: normal;
            color: $mainColor;
            &.free-inspection {
              background-image: url('../images/reasons-10/1.png');
            }
            &.fast-turnaround {
              background-image: url('../images/reasons-10/2.png');
            }
            &.pre-calibration {
              background-image: url('../images/reasons-10/3.png');
            }
            &.repair-faults {
              background-image: url('../images/reasons-10/4.png');
            }
            &.equipment-valet {
              background-image: url('../images/reasons-10/5.png');
            }
            &.check-accessories {
              background-image: url('../images/reasons-10/6.png');
            }
            &.backup-battery {
              background-image: url('../images/reasons-10/7.png');
            }
            &.replacement-battery {
              background-image: url('../images/reasons-10/8.png');
            }
            &.instrument-firmware {
              background-image: url('../images/reasons-10/9.png');
            }
            &.major-faults {
              background-image: url('../images/reasons-10/10.png');
            }
            span {
              height: 100%;
              width: 100%;
              display: table-cell;
              vertical-align: middle;

              h5 {
                padding: 0;
                margin: 0;
                font-family: $monts;
                color: $headlineColor;
                font-size: 20px;
                line-height: 20px;
              }
            }
          }
        }
      }
    }
  }
}