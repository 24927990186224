.funct-page {
  ul {
    padding: 5px 0 15px 35px;
  }
  .moreInfo {
    border: 1px solid $lightGrey;
    ul#infoTbbs {
      padding: 0;
      width: 100%;
      display: table;
      li {
        display: table-cell;
        float: none;
        width: auto;
        font-style: normal;
        font-size: 16px;
        font-weight: 600;
        text-align: center;
        border-top: 3px solid #f8f7f7;
        background: #f8f7f7;
        border-right: 1px solid $lightGrey;
        a {
          color: $mainColor;
          font-weight: 600;
        }
        &.active {
          border-top: 3px solid $headlineColor;
          a {
            color: $headlineColor;
          }
        }
      }
    }
    .tab-content {
      padding: 10px;
    }
  }
  .pdf-download, .function-detail {
    .button-info {
      width: 100%;
      font-weight: bold;
      font-size: 15px;
      line-height: 32px;
    }
    ul.pdf-list {
      padding-left: 0;
      list-style: none;
    }
    .panel-heading {
      background: $headlineColor;
      color: #fff;
      h3 {
        width: 100%;
        a {
          display: block;
          width: 100%;
          text-align: left;
          text-decoration: none;
          &:hover, &:active {
            text-decoration: none;
          }
          &:before {
            font-family: 'FontAwesome';
            content: "\f068";
            font-size: 1.4em;
            margin-right: 10px;
            vertical-align: middle;
            font-weight: normal;
            font-style: normal;
            text-decoration: inherit;
          }
          &.collapsed {
            &:before {
              font-family: 'FontAwesome';
              content: "\f067";
              font-size: 1.4em;
              margin-right: 10px;
              vertical-align: middle;
              font-weight: normal;
              font-style: normal;
              text-decoration: inherit;
            }
          }
        }
      }
      a:before {

      }
    }
  }
  .pdf-download {
    padding-right: 0;
    ul.pdf-list {
      list-style: none;
      li {
        list-style: none;
        padding: 5px 0;
        .fa-file-pdf-o {
          font-size: 20px;
          line-height: 20px;
          color: $headlineColor;
        }
        a {
          margin-left: 12px;
          font-size: 16px;
          line-height: 20px;
        }
      }
    }
  }
  .function-detail {
    padding-left: 0;
  }

}

.tab-attribute-list {
  #filterButton {
    display: none;
  }
  form#filterSelectList {
    display: block;
  }
}

@media screen and (max-width: 1669px) {
  .funct-page {
    .col-md-10, .col-md-2 {
      width: 100%;
    }
    .hide-smaller {
      display: none;
    }
  }
}

@media screen and (max-width: 768px) {
  .function-detail, .pdf-download {
    padding-left: 0;
    padding-right: 0;
    .panel-heading {
      margin: 0;
      width: 100%;
    }

  }
  #filterButton {
    display: block;
    width: 100%;
  }
  .tab-attribute-list {
    #filterButton {
      display: block;
      width: 100%;
    }
    form#filterSelectList {
      display: none;
    }
  }
}