#castle-group-websites {
  min-height: 154px;
  background: #575756;
}

#castle-group-websites section {
  width: 100%;
  max-width: 1110px;
  display: block;
  margin: 0 auto;
  text-align: center;
}

#castle-group-websites h2 {
  font-family: $russo;
  font-size: 12px;
  line-height: 22px;
  display: inline-block;
  color: #fff;
  width: 75px;
  margin: 37px 0 0 0;
  vertical-align: top;
  text-align: left;
}

#castle-group-websites ul {
  display: inline-block;
  margin: 0 auto;
  padding: 0;
  list-style: none;
  text-align: center;
}

#castle-group-websites ul li {
  float: left;
  margin: 0 12px;
  height: 89px;
  margin-top: 10px;
  padding-top: 26px;
  list-style: none;
  font-style: normal;
}

#castle-group-websites ul li .helper {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  list-style: none;
}

#castle-group-websites ul li.castle-cloud {
  border-left: 5px solid #AFAFAF;
  border-right: 5px solid #AFAFAF;
  padding: 0 12px;
  margin: 36px 9px 0 5px;
}

#castle-group-websites ul li.active {
  padding-top: 0;
  margin-top: 9px;
}

#castle-group-websites ul li.active span {
  position: relative;
  display: block;
  font-size: 12px;
  color: #fff;
  text-align: center;
}

#castle-group-websites ul li.active img,
#castle-group-websites ul li:hover img {
  border-top: 5px solid #AFAFAF;
  border-bottom: 5px solid #AFAFAF;
  padding: 4px 0;
  margin-top: -9px;
}

#castle-group-websites ul li.active img {
  margin-top: 0;
}
@media (max-width: 1099px) {
  #castle-group-websites section {
    text-align: center;
  }

  #castle-group-websites {
    padding-left: 10px;
  }

  #castle-group-websites ul li {
    height: 85px;
  }

  #castle-group-websites ul li img {
    width: 85px;
  }

  #castle-group-websites h2 {
    width: auto;
    margin: 20px 0 5px 0;
    font-size: 20px;
  }

  #castle-group-websites ul {
    height: 150px;
    margin-left: 0;
  }

  #castle-group-websites section {
    width: 970px;
  }
}

@media (max-width: 991px) {
  #castle-group-websites section {
    width: 100%;
  }

  #castle-group-websites section {
    width: 750px;
  }

  #castle-group-websites ul li img {
    width: 75px;
  }

  #castle-group-websites h2 {
    width: initial;
    margin-top: 10px;
  }

  #castle-group-websites ul li.active img, #castle-group-websites ul li:hover img {
    border: 0;
    padding: 0;
    margin-top: 0;
  }

  #castle-group-websites ul li {
    padding-top: 0;
  }

  #castle-group-websites ul li.castle-cloud {
    border: 0;
    margin: 0 4px;
    margin-top: 10px;
    padding: 0;
    padding-top: 0px;
    height: 85px;
  }

  #castle-group-websites ul li.active {
    padding-top: 6px;
  }

  #castle-group-websites ul li.active span {
    display: none;
  }

  #castle-group-websites ul {
    height: auto;
  }
}

@media (max-width: 767px) {
  #castle-group-websites section {
    width: 100%;
  }

  #castle-group-websites h2 {
    width: initial;
  }

  #castle-group-websites ul li.active img, #castle-group-websites ul li:hover img {
    border: 0;
    padding: 0;
    margin-top: 0;
  }

  #castle-group-websites ul li {
    padding-top: 0;
  }

  #castle-group-websites ul li.castle-cloud {
    border: 0;
    margin: 0 4px;
    margin-top: 10px;
    padding: 0;
    padding-top: 0px;
    height: 85px;
  }

  #castle-group-websites ul li.active {
    padding-top: 5px;
  }

  #castle-group-websites ul li.active span {
    display: none;
  }

  #castle-group-websites ul li {
    display: inline-block;
    float: initial;
  }

}