#contact-form-area {
  form {
    label {
      display: block;
      margin: 10px 0 0 0;
    }
    input[type=text], textarea {
      display: block;
      width: 100%;
      margin: 2px 0;
    }
    textarea {
      height: 230px;
    }
    input[type=submit] {
      clear: both;
      margin: 15px 0;
      float: left;
      width: 25%;
      padding: 3px 0;
    }
    input[type=checkbox] {
      float: left;
      width: auto;
      text-align: left;
      margin: 14px 15px 0 0;
    }
    label.joinBox {
      float: left;
      width: auto;
      margin: 10px 0;
    }
    .error {
      color: $bright-red;
      margin: 0;
    }
  }
}
.endMessage {

}
@media screen and (max-width: 992px) {
  #contact-form-area {
    form {
      input[type=checkbox] {
        width: 10%;
        margin: 14px 0 0 0;
      }
      label.joinBox {
        width: 89%;
      }
    }
  }
}
.shhhh {
  display: none;
}