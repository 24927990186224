/*****************************
## Effect - Slide in on top ##
*****************************/

@mixin slide() {
	
	/* DRAWER */
	[data-effect='pure-effect-slide'] .pure-drawer {
	    z-index: 3;	
	    transition-duration: $pure-drawer-animation-duration-slide;
	}

	[data-effect='pure-effect-slide'] .pure-drawer[data-position='left'] {
		-webkit-transform: translate3d(-100%, 0, 0);
		transform: translate3d(-100%, 0, 0);
	}

	[data-effect='pure-effect-slide'] .pure-drawer[data-position='right'] {
		-webkit-transform: translate3d(100%, 0, 0);
		transform: translate3d(100%, 0, 0);	
	}

	[data-effect='pure-effect-slide'] .pure-drawer[data-position='top'] {
		-webkit-transform: translate3d(0, -100%, 0);
		transform: translate3d(0, -100%, 0);	
	}

	[data-effect='pure-effect-slide'] .pure-toggle[data-toggle='left']:checked ~ .pure-drawer[data-position='left'],
	[data-effect='pure-effect-slide'] .pure-toggle[data-toggle='right']:checked ~ .pure-drawer[data-position='right'],
	[data-effect='pure-effect-slide'] .pure-toggle[data-toggle='top']:checked ~ .pure-drawer[data-position='top'] {
		visibility: visible;
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}

	/* OVERLAY */

	[data-effect='pure-effect-slide'] .pure-overlay {
		transition-duration: $pure-drawer-animation-duration-slide;
		transition-delay: $pure-drawer-animation-duration-slide / 2;	
	}
}

@if($show-drawer == 'all') {
	@include slide;
} @else {
	@media #{$show-drawer} {
		@include slide;
	}	
}

