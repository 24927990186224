#sitemap-pages {
  ul {
    padding: 10px 0;
    margin: 0;
    list-style: none;
    li {
      list-style: none;
      font-style: normal;
    }
  }
  ul.function-list {
    li {
      padding: 0 5px 0 0;
      &:before {
        font-family: 'FontAwesome';
        content: '\f0e4';
        margin: 0 16px 0 0;
        color: #444;
      }
    }
  }
  ul.pages-list {
    li {
      padding: 0 5px 0 0;
      &:before {
        font-family: 'FontAwesome';
        content: '\f15b';
        margin: 0 16px 0 0;
        color: #444;
      }
    }
  }
  ul.info-list {
    li {
      padding: 0 5px 0 0;
      &:before {
        font-family: 'FontAwesome';
        content: '\f129';
        margin: 0 16px 0 0;
        color: #444;
      }
    }
  }
}