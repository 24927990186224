ul.page-list {
  list-style: none;
  padding: 0;
  margin: 0 0 20px 0;
  li {
    list-style: none;
    font-style: normal;
    blockquote {
      background: $lightGrey;
      border-left: 4px solid $headlineColor;
      h2 {
        margin: 0 0 5px 0;
        a {
          color: $headlineColor;
          text-decoration: none;
          &:hover {
            color: $mainColor;
          }
        }
      }
      p {
        margin: 0 0 2px 0;
      }
    }
  }
}