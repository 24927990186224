nav {
  width: 100%;
  background: $headlineColor;
  height: 50px;
  box-shadow: 0 4px 8px 0 #cbcbcb;
  margin-bottom: 10px;
  .menu {
    padding: 0 18%;

    .menu-left {
      float: left;
    }
    .menu-right {
      float: right;
    }
    ul {
      list-style: none;
      li {
        list-style: none;
        display: inline-block;
        border-collapse: collapse;
        line-height: 50px;
        border-right: 1px solid $lightMain;
        font-weight: 400;
        font-style: normal;
        font-size: 12px;
        text-transform: uppercase;
        &:first-child {
          border-left: 1px solid $lightMain;
        }
        &:hover {
          background-color: $lightMain;
        }
        a {
          color: #fff;
          display: block;
          width: 100%;
          height: 100%;
          padding: 0 15px;
        }
        &.drop_functs, &.drop_pages {
          position: relative;
        }
        &.cart_menu {
          background-color: #ba80a0;
          position: relative;
        }
      }
    }
    .cart-shower {
      display: none;
      position: absolute;
      top: 50px;
      right: 0;
      width: 600px;
      background-color: #fff;
      text-transform: none;
      z-index: 10;
      border: 2px solid #ba80a0;
      padding: 10px 0;
      ul {
        padding: 0;
        margin: 0;
        background-color: #fff;
        li {
          background-color: #fff;
          width: 100%;
          font-size: 13px;
          line-height: 15px;
          border: 0;
          padding: 5px 10px;
        }
      }
    }
    ul.drop_menu_functions, ul.drop_menu_pages {
      display: none;
      position: absolute;
      top: 50px;
      left: 0;
      background-color: $lightMain;
      box-shadow: 0 4px 8px 0 #cbcbcb;
      z-index: 10;
      padding: 0;
      li {
        display: block;
        width: 400px;
        border: none;
        border-bottom: 1px solid #eaeaea;
        line-height: 30px;
        text-transform: uppercase;
        font-size: 12px;
        &:hover {
          background-color: $headGrey;
        }
      }
    }
  }
}
.contButt {
  position: relative;
  ul.addButt {
    position: absolute;
    top: 10px;
    right: 0;
    li {
      float: right;
      list-style: none;
    }
  }
}
@media screen and (max-width: 1280px) {
  nav {
    .menu {
      padding: 0 3%;
    }
  }
}
