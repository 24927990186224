#tab-data-table {
  width: 100%;
  ul.nav-tabs {

    padding: 0;

    li {

      font-style: normal;
      a {
        color: $headlineColor;
        border-top: 2px solid $lightGrey;
      }
      &.active {

        font-weight: 700;
        a {
          border-top: 2px solid $headlineColor;
        }
      }

    }
  }
  .band-list-table {
    margin: 20px 0 10px 0;
  }
  .tab-attribute-list {
    h3 {
      font-weight: 600;
      font-size: 16px;
      margin: 10px 0 5px 0;
    }
    ul {
      padding: 1px 0 15px 5px;
      list-style: none;
      li {
        list-style: none;
        input {
          border: 1px solid $lightGrey;
        }
        label {
          margin: 0 0 0 10px;
          font-weight: normal;
          font-style: normal;
        }
      }
    }
  }

  &::after {
    clear: both;
  }
}

.end-tabs {
  clear: both;
  margin: 10px 0;
  border-bottom: 1px solid $lightGrey;
}

#footable, #footable2 {
  font-size: 1.4rem;
  .footable-filtering {
    .form-group {
      padding: 0 0 15px 0;
    }
    .sr-only1 {
      margin: 0 10px 0 0;
      padding: 0;
      font-size: 14px;
      color: $headlineColor;
      font-weight: 400;
    }
  }
  tr.footable-header {
    background: $headlineColor;
    color: #fff;
    th {
      padding: 4px 6px;
    }
  }
  tbody {
    tr {
      &:nth-child(odd) {
        background: $tableOdd;
      }
      &:nth-child(even) {
        background: $tableEven;
      }
      td {
        padding: 4px 6px;
      }
    }
  }
}