/*************************
## Effect - Scale Up ##
*************************/

@mixin effect-scaleUp() {
	/* CONTAINER */

	[data-effect='pure-effect-scaleUp'] {
		-webkit-perspective: 1500px;
		perspective: 1500px;
	}

	[data-effect='pure-effect-scaleUp'][data-position='left'] {
		-webkit-perspective-origin: 0% 50%;
		perspective-origin: 0% 50%;
	}

	[data-effect='pure-effect-scaleUp'][data-position='right'] {
		-webkit-perspective-origin: 50% 50%;
		perspective-origin: 50% 50%;
	}

	[data-effect='pure-effect-scaleUp'][data-position='top'] {
		-webkit-perspective-origin: 50% 0;
		perspective-origin: 50% 0;
	}

	/* DRAWER */
	
	[data-effect='pure-effect-scaleUp'] .pure-drawer {	
		transition-duration: $pure-drawer-animation-duration-scaleUp;
	}

	[data-effect='pure-effect-scaleUp'] .pure-drawer[data-position='left'] {
	    -webkit-transform: translate3d(0, 0, -$drawer-width-small);
		transform: translate3d(0, 0, -$drawer-width-small);
		@media #{$medium-up} {
			-webkit-transform: translate3d(0, 0, -$drawer-width-medium);
			transform: translate3d(0, 0, -$drawer-width-medium);
		}
		@media #{$large-up} {
			-webkit-transform: translate3d(0, 0, -$drawer-width-large);
			transform: translate3d(0, 0, -$drawer-width-large);
		}
	}

	[data-effect='pure-effect-scaleUp'] .pure-drawer[data-position='right'] {
		-webkit-transform: translate3d($drawer-width-small, 0, -$drawer-width-small);
		transform: translate3d($drawer-width-small, 0, -$drawer-width-small);
		@media #{$medium-up} {
			-webkit-transform: translate3d($drawer-width-medium, 0, -$drawer-width-medium);
			transform: translate3d($drawer-width-medium, 0, -$drawer-width-medium);
		}
		@media #{$large-up} {
			-webkit-transform: translate3d($drawer-width-large, 0, -$drawer-width-large);
			transform: translate3d($drawer-width-large, 0, -$drawer-width-large);
		}
	}

	[data-effect='pure-effect-scaleUp'] .pure-drawer[data-position='top'] {
		-webkit-transform: translate3d(0, 0, -$drawer-height-small);
		transform: translate3d(0, 0, -$drawer-height-small);
		@media #{$medium-up} {
			-webkit-transform: translate3d(0, 0, -$drawer-height-medium);
			transform: translate3d(0, 0, -$drawer-height-medium);
		}
		@media #{$large-up} {
			-webkit-transform: translate3d(0, 0, -$drawer-height-large);
			transform: translate3d(0, 0, -$drawer-height-large);
		}
	}

	[data-effect='pure-effect-scaleUp'] .pure-toggle[data-toggle='left']:checked ~ .pure-drawer[data-position='left'],
	[data-effect='pure-effect-scaleUp'] .pure-toggle[data-toggle='right']:checked ~ .pure-drawer[data-position='right'],
	[data-effect='pure-effect-scaleUp'] .pure-toggle[data-toggle='top']:checked ~ .pure-drawer[data-position='top'] {
		visibility: visible;
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}

	/* PUSHER */
	
	[data-effect='pure-effect-scaleUp'] .pure-pusher-container .pure-pusher {
		transition-duration: $pure-drawer-animation-duration-scaleUp;
	}

	[data-effect='pure-effect-scaleUp'] .pure-toggle[data-toggle='left']:checked ~ .pure-pusher-container .pure-pusher {
		-webkit-transform: translate3d($drawer-width-small, 0, 0);
		transform: translate3d($drawer-width-small, 0, 0);
	    @media #{$medium-up} {
			-webkit-transform: translate3d($drawer-width-medium, 0, 0);
			transform: translate3d($drawer-width-medium, 0, 0);
		}
		@media #{$large-up} {
			-webkit-transform: translate3d($drawer-width-large, 0, 0);
			transform: translate3d($drawer-width-large, 0, 0);
		}
	}

	[data-effect='pure-effect-scaleUp'] .pure-toggle[data-toggle='right']:checked ~ .pure-pusher-container .pure-pusher {
		-webkit-transform: translate3d(-$drawer-width-small, 0, 0);
		transform: translate3d(-$drawer-width-small, 0, 0);
		@media #{$medium-up} {
			-webkit-transform: translate3d(-$drawer-width-medium, 0, 0);
			transform: translate3d(-$drawer-width-medium, 0, 0);
		}
		@media #{$large-up} {
			-webkit-transform: translate3d(-$drawer-width-large, 0, 0);
			transform: translate3d(-$drawer-width-large, 0, 0);
		}
	}

	[data-effect='pure-effect-scaleUp'] .pure-toggle[data-toggle='top']:checked ~ .pure-pusher-container .pure-pusher {
		-webkit-transform: translate3d(0, $drawer-height-small, 0);
		transform: translate3d(0, $drawer-height-small, 0);
		@media #{$medium-up} {
			-webkit-transform: translate3d(0, $drawer-height-medium, 0);
			transform: translate3d(0, $drawer-height-medium, 0);
		}
		@media #{$large-up} {
			-webkit-transform: translate3d(0, $drawer-height-large, 0);
			transform: translate3d(0, $drawer-height-large, 0);
		}
	}

	/* OVERLAY */

	[data-effect='pure-effect-scaleUp'] .pure-overlay {
		transition-duration: $pure-drawer-animation-duration-scaleUp;
		transition-delay: $pure-drawer-animation-duration-scaleUp / 2;	
	}
}

@if($show-drawer == 'all') {
	@include effect-scaleUp;
} @else {
	@media #{$show-drawer} {
		@include effect-scaleUp;
	}	
}