#off10 {
  height: 137px;
  background: url('../../images/10_off.png') center center no-repeat #94226e;
  background-size: cover;
  a {
    display: block;
    height: 100%;
    width: 100%;
  }
}
@media (max-width: 767px) {

  #off10 {
    display: none;
  }
}