@media (max-width: 1199px) {
  #home-page {
    #center-col {

      .find-calibration,
      .list-10 {
        text-align: center;
        margin: 0 auto;
        width: 100%;
        max-width: 495px;
      }

      .insta-quote,
      .we-are,
      .list-10 {
        padding: 0;
        width: 100%;
        max-width: 495px;
        margin: 5px 0;
        text-align: left;
      }
      .reasons-10 h2 {
        margin: 0 0 20px 0;
      }
      .list-10 {
        margin: 0;
      }
    }
  }
}

@media (min-width: 993px) {

}

@media (max-width: 992px) {
  #home-page {
    #center-col {
      .insta-quote, .we-are {
        float: none;
        text-align: center;
        margin: 10px auto;
      }
      .insta-quote {
        background: $lightGrey;
        background-image: none;
        height: auto;
        .insta-quote-inside {
          padding: 10px;
        }
      }
      .we-are {
        padding: 3px;
      }
      .reasons-10 {
        .list-10 {
          margin: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  #home-page {
    #center-col {
      .insta-quote {
        h2 {
          font-size: 26px;
          line-height: 30px;
          margin: 11px 0;
        }
      }
    }
  }
}