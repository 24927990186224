.trade-in {
  max-width: 255px;
  background: $headlineColor;
  text-transform: uppercase;
  font-family: $monts;
  font-size: 20px;
  line-height: 35px;
  text-align: center;

  img {
    max-width: 100%;
  }
  a {
    color: #fff;
    text-decoration: none;
  }
}

.cart-item-list {
  border: 1px solid $lightGrey;
  padding: 5px 0;
  font-size: 14px;
  h2 {
    font-size: 2em;
    margin: 0;
    padding: 0 0 10px 6px;
  }
  .cart-item-items {

  }
}

.cart-item-link {
  text-align: center;
  margin: 0 auto;
  .btn-purple {
    width: 96%;
    color: #fff;
    background: $headlineColor;
    margin: 5px 2%;
    line-height: 33px;
  }
}

ul.quote-list {
  padding: 0 !important;
  margin: 0;
  li.cart-item {
    list-style: none;
    font-style: normal;
    font-weight: 400;
    width: 100%;
    margin: 0;
    padding: 3px 6px;
    &:nth-child(even) {
      background: $tableEven;
    }
    .cart-item-name {
      width: 100%;
      font-size: 1.0em;
    }
    .cart-item-sku, .cart-item-price, input {
      display: inline-block;
    }
    .cart-item-price, input {
      float: right;
    }
    .cart-item-sku {
      font-size: 0.9em;
    }
  }
}

.sidebar-function-list {
  ul {
    list-style: none;
    padding: 20px 0 !important;
    li {
      list-style: none;
      background: url('../../images/menuLine.png') left bottom no-repeat;
      padding: 0 0 10px 0;
      a {
        text-decoration: none;
        color: $lightMain;
        font-style: normal;
        &:hover {
          text-decoration: none;
          color: #000;
        }
      }
    }
  }
}