#footerContactForm {
  padding: 30px 0;
  background: $lightGrey;

  .container {
    max-width: 1000px;
width: 100%;
    margin: 0 auto;
    .contactText {
      min-height: 325px;
      background: url('../../images/footerContactLogo.png') center bottom no-repeat;
      h2 {
        margin: 0;
        padding: 0 10px 10px 30px;
      }
      p {
        font-size: 16px;
        line-height: 23px;
        padding: 10px 30px;
      }
    }
    .contactForm {
      form {
        font-family: $monts;
        font-size: 18px;
        input, textarea {
          width: 100%;
          display: block;
          padding: 3px 5px;
          margin: 0 0 5px 0;
        }
        textarea {
          height: 130px;
        }
        input[type="submit"] {
          font-family: $monts;
          background: $headlineColor;
          color: #fff;
          padding: 3px 8px;
        }
        label.error {
          color: $bright-red;
          font-size: 12px;
          margin: -5px 0 10px 0;
        }
        .error {
          margin: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  #footerContactForm {
    .container {
      .contactText {
        background-size: auto;
        p {
          padding: 10px 10px;
        }
        h2 {
          padding-left: 10px;
        }
      }
      .contactForm {
        margin: 20px 0;
      }
    }
  }
}
@media screen and (max-width: 434px) {
  #footerContactForm {
    .container {
      .contactText {
        min-height: 100px;
        height: auto;
        padding: 3px 3px 100px 3px;
        background-size: contain;
        h2 {
          padding-left: 10px;
        }
        p {
          padding: 10px 5px;
        }
      }
      .contactForm {
        margin: 20px 5px;
      }
    }
  }
}