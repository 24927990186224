/*******************
## Effect - FlipY ##
*******************/

@mixin effect-flipY() {
	
    /* DRAWER */
    
	[data-effect="pure-effect-flipY"] .pure-drawer {
	    z-index: 3;	
        -webkit-animation-duration: $pure-drawer-animation-duration-flipY;
        animation-duration: $pure-drawer-animation-duration-flipY;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both; 
             
	}

    [data-effect="pure-effect-flipY"] .pure-toggle[data-toggle='left']:checked ~ .pure-drawer[data-position='left'],
    [data-effect="pure-effect-flipY"] .pure-toggle[data-toggle='right']:checked ~ .pure-drawer[data-position='right'],
    [data-effect="pure-effect-flipY"] .pure-toggle[data-toggle='top']:checked ~ .pure-drawer[data-position='top'] {
        visibility: visible; 
    }

	[data-effect="pure-effect-flipY"] .pure-drawer[data-position='left'] { @extend .flipOutY; }
    [data-effect="pure-effect-flipY"] .pure-drawer[data-position='right'] { @extend .flipOutY; }
    [data-effect="pure-effect-flipY"] .pure-drawer[data-position='top'] { @extend .flipOutY; }
    
	[data-effect="pure-effect-flipY"] .pure-toggle[data-toggle='left']:checked ~ .pure-drawer[data-position='left'] { @extend .flipInY; }
    [data-effect="pure-effect-flipY"] .pure-toggle[data-toggle='right']:checked ~ .pure-drawer[data-position='right'] { @extend .flipInY; }
    [data-effect="pure-effect-flipY"] .pure-toggle[data-toggle='top']:checked ~ .pure-drawer[data-position='top'] { @extend .flipInY; }
    
    /* OVERLAY */

    [data-effect="pure-effect-flipY"] .pure-overlay {
        transition-duration: $pure-drawer-animation-duration-flipY;
        transition-delay: $pure-drawer-animation-duration-flipY / 2; 
    }
}

@if($show-drawer == 'all') {
	@include effect-flipY;
} @else {
	@media #{$show-drawer} {
		@include effect-flipY;
	}	
}

