.container {
  width: 100%;
  margin: 0;
  padding: 10px 3%
}
.no-left-margin {
  padding-left: 0;
}
.no-right-margin {
  padding-right: 0;
}
@media screen and (max-width: 768px) {
  .no-left-margin {
    padding-left: 0;
    padding-right: 0;
  }
  .no-right-margin {
    padding-right: 0;
    padding-left: 0;
  }
}