.cart-list-table {
  #footable2 {
    tbody {
      tr {
        td.total-line {
          font-weight: bold;
        }
      }
      tr.total-row {
        background: #fff !important;
      }
      tr.footable-empty {
        display: none;
      }
    }
  }
}
