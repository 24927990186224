#justCalibrate {
  padding: 20px 0 1px 0;
  text-align: center;

  .container {
    margin: 0 auto;
    max-width: 1000px;
  }

  h2 {
    text-align: center;
  }

  p {
    color: $mainColor;
    font-size: 17px;
    line-height: 24px;
    padding: 0 20px;
    text-align: center;
    &.narrow {
      padding: 0 100px;
    }
  }

  .buildRepair, .buildApproved {
    a {
      color: $headlineColor;
      &:hover {
        color: #000; 
        text-decoration: none;
      }
    }
    h3 {
      padding: 120px 0 0 0;
      background-position: top center;
      background-repeat: no-repeat;
      text-align: center;
    }
  }
  .buildRepair h3 {
    background-image: url('../../images/buildRepairServices.png');
  }
  .buildApproved h3 {
    background-image: url('../../images/buildApproved.png');
  }

  h4 {
    text-transform: uppercase;
    padding: 20px 0;
    text-align: center;
    a {
      color: $headlineColor;
    }
  }
}
@media screen and (max-width: 768px) {
  #justCalibrate {
    p {
      padding: 0 5px;
      &.narrow {
        padding: 0 10px;
      }
    }
  }
}
