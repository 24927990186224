footer {
  background: #000;
  padding: 20px 0;
  color: #fff;
  font-size: 14px;
  line-height: 21px;
  .container {
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
    .footLeft {
      text-align: left;
      position: relative;
      .links {
        display: block;
        height: 60px;

        ul {
          padding: 0;
          margin: 0;
          display: block;

          li {
            display: inline-block;
            padding: 0 15px;
            border-right: 1px solid #fff;
            a {
              display: block;
              color: #fff;
            }
            &.first {
              padding-left: 0;
            }
            &.last {
              border-right: none;
            }
          }
        }
      }

      .address {
        display: block;

        ul {

          padding: 0;
          margin: 0;
          list-style: none;
          li {
            display: block;
            padding: 0;
            margin: 1px 0;

            a {
              display: block;
              color: #fff;
            }
          }
        }
      }

      .copyrl {
        display: none;
      }
    }
    .footRight {
      text-align: right;

      .logo {
        background: url('../../images/footerLogo.png') top right no-repeat;
        height: 65px;
        width: 240px;
        float: right;
      }
      .copyrr {
        display: block;
        font-size: 10px;
        margin: 20px 0 10px 0;
      }
      ul {
        clear: both;
        display: block;
        text-align: right;
        list-style: none;
        margin: 0 auto;
        padding: 20px 0 0 0;
        width: 100%;
        li {
          display: inline-block;
          list-style: none;
          font-size: 26px;
          margin: 0 5px;
          text-align: center;
          i {
            &.fa-twitter {
              display: block;
              padding: 10px;
              background: #26a5dd;
              @include border-radius(100%);
            }
            &.fa-facebook {
              display: block;
              padding: 10px 16px;
              background: #4d67a2;
              @include border-radius(100%);
            }
            &.fa-linkedin {
              display: block;
              padding: 10px 13px;
              background: #0178b3;
              @include border-radius(100%);
            }
            &.fa-google-plus {
              display: block;
              padding: 10px 6px;
              background: #d64a36;
              @include border-radius(100%);
            }
          }
          a {
            color: #fff;
            text-decoration: none;
            display: block;

          }
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  footer {
    .container {
      .footLeft {
        text-align: center;
        margin: 10px auto 0 auto;

        .copyrl {
          display: block;
          font-size: 10px;
          margin: 20px 0 10px 0;
        }
      }
      .footRight {
        text-align: center;
        margin: 0 auto 10px auto;
        .logo {
          background: url('../../images/footerLogo.png') top center no-repeat;
          background-size: contain;
          width: 100%;
        }
        ul {
          text-align: center;

          li {
            margin: 10px 5px;
          }
        }
        .copyrr {
          display: none;
        }
      }
    }
  }
}


.functh3 {
  font-size: 24px;
  font-weight: normal;
  text-align: center;
  background: #94226e;
  color: #fff;
  padding: 20px 0;
  margin: 0 auto;
  span {
    font-weight: bold;
    letter-spacing: 0.2em;
    font-size: 36px;
    display: block;
  }
}