/******************
## Effect - Fade ##
******************/

@mixin effect-fade() {
	
    /* DRAWER */
    
	[data-effect="pure-effect-fade"] .pure-drawer {
	    z-index: 3;	
        -webkit-animation-duration: $pure-drawer-animation-duration-fade;
        animation-duration: $pure-drawer-animation-duration-fade;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both; 
             
	}

    [data-effect="pure-effect-fade"] .pure-toggle[data-toggle='left']:checked ~ .pure-drawer[data-position='left'],
    [data-effect="pure-effect-fade"] .pure-toggle[data-toggle='right']:checked ~ .pure-drawer[data-position='right'],
    [data-effect="pure-effect-fade"] .pure-toggle[data-toggle='top']:checked ~ .pure-drawer[data-position='top'] {
        visibility: visible; 
    }

	[data-effect="pure-effect-fade"] .pure-drawer[data-position='left'] { @extend .fadeOutLeft; }
    [data-effect="pure-effect-fade"] .pure-drawer[data-position='right'] { @extend .fadeOutRight; }
    [data-effect="pure-effect-fade"] .pure-drawer[data-position='top'] { @extend .fadeOutUp; }
    
	[data-effect="pure-effect-fade"] .pure-toggle[data-toggle='left']:checked ~ .pure-drawer[data-position='left'] { @extend .fadeInLeft; }
    [data-effect="pure-effect-fade"] .pure-toggle[data-toggle='right']:checked ~ .pure-drawer[data-position='right'] { @extend .fadeInRight; }
    [data-effect="pure-effect-fade"] .pure-toggle[data-toggle='top']:checked ~ .pure-drawer[data-position='top'] { @extend .fadeInDown; }
    
    /* OVERLAY */

    [data-effect="pure-effect-fade"] .pure-overlay {
        transition-duration: $pure-drawer-animation-duration-fade;
        transition-delay: $pure-drawer-animation-duration-fade / 2; 
    }
}

@if($show-drawer == 'all') {
	@include effect-fade;
} @else {
	@media #{$show-drawer} {
		@include effect-fade;
	}	
}