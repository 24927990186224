/*********************
## Effect - Push ##
*********************/

@mixin effect-push() {
	
	/* DRAWER */
	[data-effect='pure-effect-push'] .pure-drawer {
		transition-duration: $pure-drawer-animation-duration-push;
	}

	[data-effect='pure-effect-push'] .pure-drawer[data-position='left'] {
		-webkit-transform: translate3d(-100%, 0, 0);
		transform: translate3d(-100%, 0, 0);
	}

	[data-effect='pure-effect-push'] .pure-drawer[data-position='right'] {
		-webkit-transform: translate3d(100%, 0, 0);
		transform: translate3d(100%, 0, 0);
	}

	[data-effect='pure-effect-push'] .pure-drawer[data-position='top'] {
		-webkit-transform: translate3d(0, -100%, 0);
		transform: translate3d(0, -100%, 0);
	}

	[data-effect='pure-effect-push'] .pure-toggle[data-toggle='left']:checked ~ .pure-drawer[data-position='left'],
	[data-effect='pure-effect-push'] .pure-toggle[data-toggle='right']:checked ~ .pure-drawer[data-position='right'],
	[data-effect='pure-effect-push'] .pure-toggle[data-toggle='top']:checked ~ .pure-drawer[data-position='top'] {
		visibility: visible;
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}

	/* PUSHER */
	
	[data-effect='pure-effect-push'] .pure-pusher-container .pure-pusher {
		transition-duration: $pure-drawer-animation-duration-push;
	}

	[data-effect='pure-effect-push'] .pure-toggle[data-toggle='left']:checked ~ .pure-pusher-container .pure-pusher {
		-webkit-transform: translate3d($drawer-width-small, 0, 0);
		transform: translate3d($drawer-width-small, 0, 0);
	    @media #{$medium-up} {
			-webkit-transform: translate3d($drawer-width-medium, 0, 0);
			transform: translate3d($drawer-width-medium, 0, 0);
		}
		@media #{$large-up} {
			-webkit-transform: translate3d($drawer-width-large, 0, 0);
			transform: translate3d($drawer-width-large, 0, 0);
		}
	}

	[data-effect='pure-effect-push'] .pure-toggle[data-toggle='right']:checked ~ .pure-pusher-container .pure-pusher {
		-webkit-transform: translate3d(-$drawer-width-small, 0, 0);
		transform: translate3d(-$drawer-width-small, 0, 0);
		@media #{$medium-up} {
			-webkit-transform: translate3d(-$drawer-width-medium, 0, 0);
			transform: translate3d(-$drawer-width-medium, 0, 0);
		}
		@media #{$large-up} {
			-webkit-transform: translate3d(-$drawer-width-large, 0, 0);
			transform: translate3d(-$drawer-width-large, 0, 0);
		}
	}

	[data-effect='pure-effect-push'] .pure-toggle[data-toggle='top']:checked ~ .pure-pusher-container .pure-pusher {
		-webkit-transform: translate3d(0, $drawer-height-small, 0);
		transform: translate3d(0, $drawer-height-small, 0);
		@media #{$medium-up} {
			-webkit-transform: translate3d(0, $drawer-height-medium, 0);
			transform: translate3d(0, $drawer-height-medium, 0);
		}
		@media #{$large-up} {
			-webkit-transform: translate3d(0, $drawer-height-large, 0);
			transform: translate3d(0, $drawer-height-large, 0);
		}
	}

	/* OVERLAY */

	[data-effect='pure-effect-push'] .pure-overlay {
		transition-duration: $pure-drawer-animation-duration-push;
		transition-delay: $pure-drawer-animation-duration-push / 2;	
	}
}

@if($show-drawer == 'all') {
	@include effect-push;
} @else {
	@media #{$show-drawer} {
		@include effect-push;
	}	
}