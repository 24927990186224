.pure-drawer {
  color: #fff;
  padding: 40px 1% 0 1%;
  background-color: #222;
}
#mobile-menu {
  margin: 20px 0 0 0;
  height: 86%;
  overflow: scroll;
  font-size: 16px;
  ul {
    background-color: #222;
    li {
      background-color: #222;
      font-style: normal;
      a {
        display: block;
        color: #fff;
        width: 100%;
        height: 100%;
        padding-top: 12px;
        padding-bottom: 12px;
      }
    }
  }
  .navbar-default .navbar-collapse, .navbar-default .navbar-form {
    border-color: $mainColor;
  }
  .navbar-default {
    background-color: #222;
    background-image: none;
    box-shadow: none;
    border: 0;
    border-radius: 0;
    .navbar-nav {
      li {
        &.dropdown {
          border: 1px solid $mainColor;
          width: 100%;
          font-size: 16px;
        }
        &.main-li {
          display: inline-block;
          width: 48%;
          font-size: 14px;
          vertical-align: middle;
          border: 1px solid #000;
          background: $headlineColor;
          color: #fff;
          text-align: center;
          border-radius: 6%;
          a {
            color: #fff;
            padding-top: 6px;
            padding-bottom: 6px;
          }
        }
      }
      .open {
        background: #563037;
        .dropdown-menu {
          padding: 0;
          width: 100%;
          background: #0d2131;
          li {
            border: 1px solid $mainColor;
          }
        }
        a {
          display: block;
          padding-top: 12px;
          padding-bottom: 12px;
          width: 100%;
          height: 100%;
          font-size: 16px;
          &:hover {
            background: #000;
          }
        }
      }
    }
  }
  .mobile-side-quote {
    border: 1px solid $lightGrey;
    padding: 3px;
    h2 {
      color: #fff;
      font-size: 16px;
      padding: 5px;
      margin: 0;
    }
  }
}