.faq {
  .panel-group {
    .panel-default {
      .panel-heading {
        background-color: $headlineColor;
        @include background-gradient($headlineColor, $lightMain);
        h2.panel-title {
          font-family: $monts;
          color: #fff;
          font-size: 18px;
          line-height: 22px;
          margin: 0;
          a {
            color: #fff;
          }
        }
      }
    }
  }
  text-align: left;

  p {
    font-size: 14px;
    line-height: 21px;
  }
  ul {
    padding: 0 0 20px 40px;
    li {

    }
  }
  a {
    color: $headlineColor;
    font-weight: bold;
    text-decoration: none;

  }
}