body {
  font-family: $monts;
  font-size: $fontSize;
  color: $mainColor;
}

h1, h2 {
  font-family: $russo;
  color: $headlineColor;
  font-weight: normal;
}

h3 {
  font-weight: 700;
}

ul, li {
  padding: 0;
  margin: 0;
}

li {
  font-weight: 500;
  font-style: italic;
}

a {
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}

