header {
  height: 100px;
  display: table;
  width: 100%;
  background: #000;
  padding: 0 3%;
  .header-left, .header-center, .header-right {
    display: table-cell;
    height: 100%;
  }
  .header-left, .header-right {
    width: 16%;
  }
  .header-left {
    text-align: center;
    vertical-align: middle;
    img {
      width: 100%;
      max-width: 350px;
      max-height: 96px;
    }
  }
  .header-center {
    padding: 0 0 16px 0;
    vertical-align: bottom;
    a {
      display: inline-block;

      width: 10%;
      color: $headGrey;
      .fa {
        color: $headGrey;
        font-size: 42px;
        margin-left: 1%;
      }
    }
    .contact-line {
      display: inline-block;
      width: 89%;
      color: $headGrey;
      font-size: 18px;
      text-align: right;
      vertical-align: bottom;
      span {
        color: #fff;
      }
      a {
        float: none;
        width: auto;
        color: $lightMain;
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  header {
    .header-left {
      width: 20%;
    }
    .header-center {
      width: 80%;
    }
    .header-right {
      width: 0;
    }
  }
}