#checkoutForm {
  input[type=text], textarea, select {
    width: 100%;
  }
  input[type=text].error, textarea.error {
    border: 1px solid $bright-red;
  }
  textarea {
    height: 70px;
  }
  label {
    font-weight: 400;
  }
  option {
    padding: 10px 0;
  }
  input[type=radio] {
    float: left;
    clear: both;
    padding: 0 10px 5px 0;
  }
  span {
    float: left;
    margin-left: 10px;
  }
  .error {
    color: $bright-red;
    font-weight: normal;
  }
  .collect-row {
    display: none;
  }
}

#rootwizard {
  ul.tab-index {
    height: 0;
    overflow: hidden;
  }
  .progress-bar {
    background-color: $headlineColor;
    background-image: linear-gradient(to bottom, $headlineColor 0, $lightMain 100%);
  }
  ul.pager {
    clear: both;
    width: 100%;
    margin-top: 20px;
    li.previous, li.next {
      a {
        font-style: normal;
        color: #fff;
        background: $headlineColor;
        &:hover {
          color: #fff;
          background: $lightMain;
        }
      }
    }
    li.disabled {
      a {
        background: #fff;
        color: $lightGrey;
      }
    }
  }
}
