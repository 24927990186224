.button-info, .btn-purple, .contactSubmit {
  background-color: $headlineColor;
  color: #fff;
  &:hover {
    background-color: $mainColor;
    color: #fff;
  }
}
.padding-right-30 {
  padding-right: 30px;
}

.mainShadow {
  clear: both;
  background-image: url(../images/shadow.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 25px;
  margin-top: 5px;
  width: 100%;
}

.sideShadow {
  clear: both;
  background-image: url(../images/sideShadow.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 25px;
  margin-top: 5px;
  width: 100%;
}

.margin-1pc {
  padding-left: 1%;
  padding-right: 1%;
}
.spacer-10 {
  padding-top: 10px;
}
.need-help {
  padding-bottom: 20px;
  max-width: 240px;
}
.btn-blue {
  background: $buyNow;
  color: #fff;
  &:hover {
    background: #333;
    color: #fff;
  }
}

@media screen and (max-width: 768px) {
  #center-col {
    padding: 0;
  }
  .btn-blue {
    width: 100%;
  }
}