// Fonts
$russo: 'Russo One', sans-serif;
$monts: 'Montserrat', sans-serif;

// Main Font Size
$fontSize: 15px;
$h1Size: 48px;
$h2Size: 32px;
$h3Size: 24px;

// Colors
$mainColor: #333;
$lightMain: #B86C9F;
$headlineColor: #972470;
$headGrey: #9a9999;
$lightGrey: #eaeaea;
$bright-red: #D80031;
$buyNow: #167ac6;

$tableOdd: #EBD6E0;
$tableEven: #F5EBFE;