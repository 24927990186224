#buildQuote {
  background: #e8e8e8;
  padding: 10px 0 30px 0;
  width: 100%;
  text-align: center;
  .container {
    width: 98%;
    max-width: 1000px;
    margin: 0 auto;
  }

  h2 {
    padding: 0;
    margin: 0 0 20px 0;
    font-size: 28px;
    line-height: 28px;
    text-transform: uppercase;
    text-align: center;
    color: $headlineColor;
  }
  p {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    padding: 5px 80px;
  }

  ul {
    display: block;
    text-align: center;
    padding: 0;

    li {
      display: inline-block;
      width: 24%;
      height: 50px;
      font-style: normal;
      .buildWrap {
        display: table;
        height: 50px;
        width: 100%;
      }
      .buildBox {
        padding: 0;
        border: 2px solid #d8d8d8;
        background: #fff;
        color: $mainColor;
        height: 50px;
        display: table;
        text-align: center;
        vertical-align: middle;
        width: 100%;
        &:hover {
          background: $headlineColor;
          border: 2px solid $lightMain;
        }
        a {
          display: table-cell;
          height: 100%;
          width: 100%;
          vertical-align: middle;
          color: $mainColor;
          text-decoration: none;
          &:hover {
            background: $headlineColor;
            color: #fff;
          }
        }
      }
    }
  }

}

@media (max-width: 992px) {
  #buildQuote {
    p {
      padding: 5px;
    }
    ul {
      li {
        width: 49%;
      }
    }
  }
}

@media (max-width: 580px) {
  #buildQuote {
    ul {
      li {
        width: 100%;
      }
    }
  }
}