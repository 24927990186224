/**************
## Variables ##
**************/

$show-drawer: 'all' !default;

$pure-drawer-animation-duration: 500ms !default;
$pure-drawer-animation-duration-slide: $pure-drawer-animation-duration !default;
$pure-drawer-animation-duration-reveal: $pure-drawer-animation-duration !default;
$pure-drawer-animation-duration-slideAlong: $pure-drawer-animation-duration !default;
$pure-drawer-animation-duration-reverseSlide: $pure-drawer-animation-duration !default;
$pure-drawer-animation-duration-scaleDown: $pure-drawer-animation-duration !default;
$pure-drawer-animation-duration-scaleUp: $pure-drawer-animation-duration !default;
$pure-drawer-animation-duration-scaleRotate: $pure-drawer-animation-duration !default;
$pure-drawer-animation-duration-fall: $pure-drawer-animation-duration !default;
$pure-drawer-animation-duration-push: $pure-drawer-animation-duration !default;
$pure-drawer-animation-duration-bounce: 1000ms !default;
$pure-drawer-animation-duration-fade: 1000ms !default;
$pure-drawer-animation-duration-flipX: 1000ms !default;
$pure-drawer-animation-duration-flipY: 1000ms !default;
$pure-drawer-animation-duration-zoom: 1000ms !default;

$toggler-color: lighten(#fff, 20%) !default;
$toggler-checked-color: #fff !default;
$toggler-focus-color: #fff !default;

$drawer-bgcolor: #222 !default;

// For 'left' and 'right' positioned drawers.
$drawer-width-small: 100% !default;
$drawer-width-medium: 100% !default;
$drawer-width-large: 300px !default;

// For 'top' positioned drawers.
$drawer-height-small: 100% !default;
$drawer-height-medium: 100% !default;
$drawer-height-large: 100% !default;

$pusher-bgcolor: #fff !default;

$overlay-bgcolor: rgba(0,0,0,0.4) !default;

/*************
## Required ##
*************/

html, body {
	height: 100%;
}

body {
	margin: 0;
}

/**************
## Container ##
**************/

.pure-container {
	position: relative;
	height: 100%;
	-webkit-overflow-scrolling: touch;
}

/************
## Toggler ##
************/

.pure-toggle {
    left: -9999px;
    position: absolute;
    top: -9999px;
    &:focus ~ .pure-toggle-label {
    	border-color: $toggler-focus-color;
    	color: $toggler-focus-color;
    }
}

@mixin pure-toggle-label() {
	cursor: pointer;
    display: block;
    position: fixed;
    top: 10px;
    z-index: 99;
    color: $toggler-color;
    width: 20px;
	height: 20px;
    transition: all 400ms ease-in-out;  
    border: 0px solid $toggler-color;
    border-radius: 50%;
    -webkit-user-select: none; 
	-moz-user-select: none;     
	-ms-user-select: none;     
	-o-user-select: none;
	user-select: none; 
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0); // Removes grey background on link taps in ios
    &:hover {
    	border-color: $toggler-focus-color;
    	color: $toggler-focus-color;
    	.pure-toggle-icon,
    	.pure-toggle-icon:before,
    	.pure-toggle-icon:after {
    		background-color: $toggler-focus-color;
    	}
    }
    &:active {
    	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }
    .pure-toggle-icon, 
    .pure-toggle-icon:before, 
    .pure-toggle-icon:after {
    	position: absolute;
    	top: 80%;
  		left: 50%;
  		height: 3px;
  		width: 30px;
  		cursor: pointer;
  		background: $toggler-color;;
  		display: block;
  		content: '';
  		transition: all 500ms ease-in-out;
	}
	.pure-toggle-icon {
  		transform: translate3d(-50%, -4px, 0);
  		-webkit-transform: translate3d(-50%, -4px, 0);
	}
	.pure-toggle-icon:before {
  		transform: translate3d(-50%, -12px, 0);
  		-webkit-transform: translate3d(-50%, -12px, 0);
	}
	.pure-toggle-icon:after {
  		transform: translate3d(-50%, 8px, 0);
  		-webkit-transform: translate3d(-50%, 8px, 0);
	}
}

.pure-toggle-label {
	display: none;
	@if($show-drawer == 'all') {
		@include pure-toggle-label;
	} @else {
		@media #{$show-drawer} {
			@include pure-toggle-label;
		}	
	}
}

.pure-toggle-label[data-toggle-label='left'] {
	left: 15px;
	right: auto; // Not needed?
}

.pure-toggle-label[data-toggle-label='right'] {
	right: 56 / 2 + px;
	left: auto;
}

.pure-toggle-label[data-toggle-label='top'] {
	left: 50%;
	-webkit-transform: translate3d(-50%, 0, 0);
	transform: translate3d(-50%, 0, 0);
}

.pure-toggle[data-toggle='left']:checked ~ .pure-toggle-label:not([data-toggle-label='left']),
.pure-toggle[data-toggle='right']:checked ~ .pure-toggle-label:not([data-toggle-label='right']),
.pure-toggle[data-toggle='top']:checked ~ .pure-toggle-label:not([data-toggle-label='top']) {
	opacity: 0;
	z-index: -1;
}

.pure-toggle[data-toggle='left']:checked ~ .pure-toggle-label[data-toggle-label='left'],
.pure-toggle[data-toggle='right']:checked ~ .pure-toggle-label[data-toggle-label='right'],
.pure-toggle[data-toggle='top']:checked ~ .pure-toggle-label[data-toggle-label='top']  {
	border-color: $toggler-checked-color;
	color: $toggler-checked-color;
	.pure-toggle-icon {
	  	background-color: transparent;
	}
	.pure-toggle-icon:before, .pure-toggle-icon:after {
	  	top: 0;
	}
	.pure-toggle-icon:before {
	  	transform: translateX(-50%) rotate(45deg);
	  	-webkit-transform: translateX(-50%) rotate(45deg);
	}
	.pure-toggle-icon:after {
	  	transform: translateX(-50%) translateY(-10px) rotate(-45deg);
	  	-webkit-transform: translateX(-50%) translateY(-10px) rotate(-45deg);
	  	top: 10px;
	}
}

/***********
## Drawer ##
***********/

@mixin pure-drawer() {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1;
	height: 100%;
	visibility: hidden;
	background-color: $drawer-bgcolor;

	transition-property: all;
	transition-duration: $pure-drawer-animation-duration;
	transition-timing-function: ease-out;

	width: $drawer-width-small;
	@media #{$medium-up} { width: $drawer-width-medium; }
	@media #{$large-up} { width: $drawer-width-large; }
} 

.pure-drawer {
	@if($show-drawer == 'all') {
		@include pure-drawer;
	} @else {
		@media #{$show-drawer} {
			@include pure-drawer;
		}	
	}
} 

.pure-drawer[data-position='right']  {
	left: auto;
	right: 0;
}

.pure-drawer[data-position='top'] {
	height: $drawer-height-small;
	width: 100%;
	@media #{$medium-up} { height: $drawer-height-medium; }
	@media #{$large-up} { height: $drawer-height-large; }
}

/*********************
## Pusher Container ##
*********************/

.pure-pusher-container {
	position: relative;
	height: 100%;
	overflow: hidden;
}

/***********
## Pusher ##
***********/

.pure-pusher {
	position: relative;
	height: 100%;
	overflow-y: auto;
	left: 0;
	z-index: 2;
	background-color: $pusher-bgcolor;
	
	transition-property: transform;
	transition-duration: $pure-drawer-animation-duration;
	transition-timing-function: ease-out;
}

/*******************
## Pusher Overlay ##
*******************/

@mixin pure-overlay() {
	position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	width: 0;
	height: 0;
	opacity: 0;
	background-color: $overlay-bgcolor;
	
	transition-property: opacity;
	transition-duration: $pure-drawer-animation-duration;
	transition-delay: $pure-drawer-animation-duration;
	transition-timing-function: ease-in-out;
}

.pure-overlay {
	@if($show-drawer == 'all') {
		@include pure-overlay;
	} @else {
		@media #{$show-drawer} {
			@include pure-overlay;
		}	
	}
}

.pure-toggle[data-toggle='left']:checked ~ .pure-overlay[data-overlay='left'] {
	left: $drawer-width-small;
	@media #{$medium-up} { left: $drawer-width-medium; }
	@media #{$large-up} { left: $drawer-width-large; }
}

.pure-toggle[data-toggle='right']:checked ~ .pure-overlay[data-overlay='right'] {
	right: $drawer-width-small;
	@media #{$medium-up} { right: $drawer-width-medium; }
	@media #{$large-up} { right: $drawer-width-large; }
}

.pure-toggle[data-toggle='top']:checked ~ .pure-overlay[data-overlay='top'] {
	top: $drawer-height-small;
	@media #{$medium-up} { top: $drawer-height-medium; }
	@media #{$large-up} { top: $drawer-height-large; }
}

.pure-toggle[data-toggle='left']:checked ~ .pure-overlay[data-overlay='left'],
.pure-toggle[data-toggle='right']:checked ~ .pure-overlay[data-overlay='right'],
.pure-toggle[data-toggle='top']:checked ~ .pure-overlay[data-overlay='top'] {
	width: 100%;
	height: 100%;
	opacity: 1;
	z-index: 2;
}

/********************************
## Fallback for older browsers ##
********************************/

.no-csstransforms3d {
	.pure-toggle[data-toggle='left']:checked ~ .pure-pusher-container .pure-pusher {
		left: $drawer-width-small;
		@media #{$medium-up} { left: $drawer-width-medium; }
		@media #{$large-up} { left: $drawer-width-large; }
	}
	.pure-toggle[data-toggle='right']:checked ~ .pure-pusher-container .pure-pusher {
		left: -$drawer-width-small;
		@media #{$medium-up} { left: -$drawer-width-medium; }
		@media #{$large-up} { left: -$drawer-width-large; }
	}
	.pure-toggle[data-toggle='top']:checked ~ .pure-pusher-container .pure-pusher {
		top: $drawer-height-small;
		@media #{$medium-up} { top: $drawer-height-medium; }
		@media #{$large-up} { top: $drawer-height-large; }
	}
}