header.m-header {
  position: fixed;
  display: table;
  height: 46px;
  background: #000;
  width: 100%;
  z-index: 10;
  .m-header-left, .m-header-center, .m-header-right {
    display: table-cell;
    height: 100%;
    vertical-align: middle;
  }
  .m-header-left, .m-header-right {
    width: 20%;
  }
  .m-header-center {
    text-align: center;
    margin: 0 auto;
  }
  .m-header-right {
    text-align: right;
    a {
      color: $headGrey;
      .fa {
        font-size: 30px;
        color: $headGrey;
      }
    }
  }
}
.head-drop {
  padding-top: 46px;
}